import { Col, Container, Row } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import BrandLogo from './BrandLogo';

import { getContact_query, getSponsors_query } from '../../api/wix-service';
import { useWix } from '../../providers/wix-provider';

import { Icon } from '../../components';

const Footer = () => {
	const getYear = new Date().getFullYear();
	const { wixClient } = useWix();
	const { data: directPartners } = useQuery(getSponsors_query(wixClient, 'DirectPartner'));
	const { data: contact } = useQuery(getContact_query(wixClient));

	return (
		<Container className="py-5">
			<Row>
				<Col xs={12} md>
					<BrandLogo />
					<small className="d-block mb-3 text-body-secondary">© ANDAR FC 2024-{getYear}</small>
				</Col>
				<Col xs={6} md>
					<h5>Parceiros Diretos</h5>
					<ul className="list-unstyled text-small">
						{directPartners?.map((partner, i) => (
							<li key={i}>
								{partner.url ? (
									<Link
										target="_blank"
										className="link-secondary text-decoration-none"
										to={partner.url}
									>
										{partner.name}
									</Link>
								) : (
									partner.name
								)}
							</li>
						))}
					</ul>
				</Col>
				<Col xs={6} md>
					<h5>Contactos</h5>
					<ul className="list-unstyled text-small">
						<li>
							<span className="fw-bold">Email: </span>
							<a
								className="link-secondary text-decoration-none"
								href={`mailto:${contact?.geralEmail}`}
							>
								{contact?.geralEmail}
							</a>
						</li>
						<li>
							<span className="fw-bold">Tel.: </span>
							<a
								className="link-secondary text-decoration-none"
								href={`tel:+351${contact?.phone}`}
							>
								+351 {contact?.phone}
							</a>
						</li>
						<li>
							<span className="fw-bold">Morada: </span>
							<a
								className="link-secondary text-decoration-none"
								href={contact?.addressLink}
								target="_blank"
								rel="noreferrer"
							>
								{contact?.address}
							</a>
						</li>
					</ul>
				</Col>
				<Col xs={12} md>
					<h5>Sobre</h5>
					<ul className="list-unstyled text-small">
						<li>
							<Link to="/about-us" className="link-secondary text-decoration-none">
								Sobre Nós
							</Link>
						</li>
					</ul>
					<div className="d-flex items-center gap-3">
						<Link to="https://youtube.com" target="_blank">
							<Icon name="youtube" size="1.75" />
						</Link>
						<Link to="https://www.instagram.com/andarfc1986" target="_blank">
							<Icon name="instagram" size="1.75" />
						</Link>
						<Link to="https://www.facebook.com/profile.php?id=61564440599534" target="_blank">
							<Icon name="facebook" size="1.75" />
						</Link>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Footer;
