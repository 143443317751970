type ProductStatusColor = 'success' | 'danger' | 'warning';
type ProductStatus = 'In Stock' | 'Out Of Stock' | 'Almost Out Of Stock';

export type Colors =
	| 'var(--bs-blue)'
	| 'var(--bs-indigo)'
	| 'var(--bs-purple)'
	| 'var(--bs-pink)'
	| 'var(--bs-red)'
	| 'var(--bs-orange)'
	| 'var(--bs-yellow)'
	| 'var(--bs-green)'
	| 'var(--bs-teal)'
	| 'var(--bs-cyan)'
	| 'var(--bs-white)'
	| 'var(--bs-gray)'
	| 'var(--bs-gray-dark)'
	| 'var(--bs-primary)'
	| 'var(--bs-secondary)'
	| 'var(--bs-success)'
	| 'var(--bs-info)'
	| 'var(--bs-warning)'
	| 'var(--bs-danger)'
	| 'var(--bs-light)'
	| 'var(--bs-dark)';
export type IconFontSizes = '2.5' | '2' | '1.75' | '1.5' | '1.25';
export type Align = 'top' | 'middle' | 'bottom';
export type InputTypes = 'range' | 'checkbox' | 'input';
export enum SortTypes {
	NUM_ASC,
	NUM_DSC,
	STR_ASC,
	STR_DSC,
}

export const SortOptions = [
	{
		id: SortTypes.NUM_ASC,
		label: 'Price (low to high)',
	},
	{
		id: SortTypes.NUM_DSC,
		label: 'Price (high to low)',
	},
	{
		id: SortTypes.STR_ASC,
		label: 'Name A-Z',
	},
	{
		id: SortTypes.STR_DSC,
		label: 'Name Z-A',
	},
];

export type Product = {
	id: string;
	name: string;
	description: string;
	price: string;
	discountedPrice?: string;
	images: string[];
	status: ProductStatus;
	statusColor: ProductStatusColor;
	quantity: number;
	discount: number;
	inStock: boolean;
	ribbons?: { text: string }[];
	filters: any;
	sku: string;
	options?: any[];
	variants?: any[];
};

export type FilterSubItem = {
	inputType: InputTypes;
	label: string;
	min?: number;
	max?: number;
	type?: 'min' | 'max';
	value?: number;
	isChecked?: boolean;
};
export type Filter = {
	label: string;
	subItems?: FilterSubItem[];
};

export type Filters = {
	searchTerm: string;
	sort: string;
	min?: string;
	max?: string;
	extraFilters?: any;
};

export type Game_Type = 'All' | 'Played' | 'Next';
export type Team = {
	id: string;
	title: string;
	image: string;
	isHouseTeam: boolean;
};
export type Game = {
	id: string;
	date: Date;
	dateFormatted: {
		time: string;
		date: string;
	};
	isFriendly: boolean;
	scoreTeam1: number;
	scoreTeam2: number;
	team1: Team;
	team2: Team;
	isMainEvent: boolean;
};

export type Sponsor_Type = 'All' | 'DirectPartner';
