import { Container } from 'react-bootstrap';
import { Footer, Header, LayoutSideMenu } from '../../layout';

const NotFound = () => (
	<LayoutSideMenu header={<Header />} footer={<Footer />}>
		<Container fluid className="p-3 px-0 py-5">
			<Container fluid className="p-0 ps-4 d-flex justify-content-between">
				<h1>Page Not Found</h1>
			</Container>
		</Container>
	</LayoutSideMenu>
);

export default NotFound;
