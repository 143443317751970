import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | undefined;

type ThemeProviderProps = {
	children?: ReactNode;
};

const ThemeContext = createContext<{ breakpoint: Breakpoint }>({
	breakpoint: undefined,
});

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
	const [breakpoint, setBreakpoint] = useState<Breakpoint>(undefined);

	const resizeEvent = () => {
		if (window.innerWidth < 576) {
			setBreakpoint(undefined);
		} else if (window.innerWidth >= 576 && window.innerWidth < 768) {
			setBreakpoint('sm');
		} else if (window.innerWidth >= 768 && window.innerWidth < 992) {
			setBreakpoint('md');
		} else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
			setBreakpoint('lg');
		} else if (window.innerWidth >= 1200 && window.innerWidth < 1400) {
			setBreakpoint('xl');
		} else if (window.innerWidth >= 1400) {
			setBreakpoint('xxl');
		}
	};

	useEffect(() => {
		resizeEvent();
		window.addEventListener('resize', resizeEvent);

		return () => {
			window.removeEventListener('resize', resizeEvent);
		};
	}, []);

	return <ThemeContext.Provider value={{ breakpoint }}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => useContext(ThemeContext);
