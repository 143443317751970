import { Container } from 'react-bootstrap';
import { Game } from '../../utils';
import TeamItem from './team-item';
import { Icon } from '../../components';

const TableItem = ({ game }: { game: Game }) => (
	<td>
		<Container className="p-3 d-flex align-items-center pe-0">
			<Container fluid className="p-0 border-1 border-end border-primary">
				<TeamItem team={game.team1} date={game.date} score={game.scoreTeam1} />
				<TeamItem team={game.team2} date={game.date} score={game.scoreTeam2} className="mt-2" />
			</Container>
			<Container fluid className="p-0 text-center" style={{ maxWidth: 100 }}>
				{new Date(game.date) > new Date() && (
					<>
						<p className="m-0 small">
							{new Date(game.date).toLocaleDateString('default', {
								day: '2-digit',
								month: '2-digit',
							})}
						</p>
						<p className="m-0 small">{game.dateFormatted.time}</p>
					</>
				)}
				{game.team1.isHouseTeam && game.scoreTeam1 > game.scoreTeam2 && (
					<Icon name="circle-fill" color="var(--bs-success)" />
				)}
				{!game.team1.isHouseTeam && game.scoreTeam1 > game.scoreTeam2 && (
					<Icon name="circle-fill" color="var(--bs-danger)" />
				)}
				{game.team2.isHouseTeam && game.scoreTeam1 < game.scoreTeam2 && (
					<Icon name="circle-fill" color="var(--bs-success)" />
				)}
				{!game.team2.isHouseTeam && game.scoreTeam1 < game.scoreTeam2 && (
					<Icon name="circle-fill" color="var(--bs-danger)" />
				)}
				{game.scoreTeam1 === game.scoreTeam2 && game.scoreTeam1 && game.scoreTeam2 && (
					<Icon name="circle-fill" color="var(--bs-warning)" />
				)}
			</Container>
		</Container>
	</td>
);

export default TableItem;
