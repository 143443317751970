import { members } from '@wix/members';
import { items } from '@wix/data';
import Cookies from 'js-cookie';
import { Game, Game_Type, Product, SortTypes, Sponsor_Type } from '../utils';
import CLUB_PLACEHOLDER_IMG from '../assets/club-placeholder.png';
import { WixClient } from '../providers/wix-provider';

export const getGames_query = (wixClient: WixClient, game_types: Game_Type[] | string) => ({
	queryKey: ['getGames', game_types],
	queryFn: async (): Promise<Game[] | { [key: string]: { games: Game[] } }[]> => {
		const { items } = await wixClient.items
			.queryDataItems({
				dataCollectionId: 'Games',
				includeReferencedItems: ['teamId1', 'teamId2', 'registeredUser'],
			})
			.find();

		if (typeof game_types === 'object') {
			const games: { [key: string]: { games: Game[] } }[] = [];

			game_types.forEach((type) => {
				if (type === 'Next') {
					games.push({
						[type]: {
							games: mapGames(
								items
									.filter(
										(game) =>
											new Date(game.data?.date.$date) >= new Date() &&
											game.data?.isMainEvent,
									)
									.sort(
										(a, b) =>
											new Date(b.data?.date.$date).getTime() -
											new Date(a.data?.date.$date).getTime(),
									)
									.reverse()
									.slice(0, 1),
							),
						},
					});
				} else if (type === 'Played') {
					games.push({
						[type]: {
							games: mapGames(
								items
									.filter((game) => new Date(game.data?.date.$date) < new Date())
									.sort(
										(a, b) =>
											new Date(b.data?.date.$date).getTime() -
											new Date(a.data?.date.$date).getTime(),
									),
							),
						},
					});
				} else if (type === 'All') {
					games.push({
						[type]: {
							games: mapGames(
								items.sort(
									(a, b) =>
										new Date(b.data?.date.$date).getTime() -
										new Date(a.data?.date.$date).getTime(),
								),
							),
						},
					});
				}
			});

			return games;
		} else {
			return mapGames(
				items.sort(
					(a, b) => new Date(b.data?.date.$date).getTime() - new Date(a.data?.date.$date).getTime(),
				),
			);
		}
	},
});

export const getSquad_query = (wixClient: WixClient) => ({
	queryKey: ['getSquad'],
	queryFn: async () => {
		const { items: players } = await wixClient.items
			.queryDataItems({
				dataCollectionId: 'Players',
				includeReferencedItems: ['positions'],
			})
			.find();

		const items = players?.map((player) => ({
			id: player.data?._id,
			name: player.data?.title,
			positions: player.data?.positions.map((position: Record<string, any> | null) => position?.title),
		}));

		return items;
	},
});

export const getInvestments_query = (wixClient: WixClient) => ({
	queryKey: ['getInvestments'],
	queryFn: async () => {
		const { items: results } = await wixClient.items
			.queryDataItems({
				dataCollectionId: 'Investments',
			})
			.ascending('Order')
			.find();

		const items = results.map((investment) => ({
			id: investment._id,
			order: investment.data?.order,
			title: investment.data?.title,
			budget: new Intl.NumberFormat('pt-PT', {
				style: 'currency',
				currency: 'EUR',
			}).format(investment.data?.budget),
			received: new Intl.NumberFormat('pt-PT', {
				style: 'currency',
				currency: 'EUR',
			}).format(investment.data?.received),
			progress: (parseInt(investment.data?.received) / parseInt(investment.data?.budget)) * 100,
		}));

		return items;
	},
});

export const getSponsors_query = (wixClient: WixClient, sponsor_type: Sponsor_Type) => ({
	queryKey: ['getSponsors', sponsor_type],
	queryFn: async () => {
		const query = wixClient.items.queryDataItems({
			dataCollectionId: 'Sponsors',
		});

		let sponsors: items.DataItem[] | undefined = [];

		if (sponsor_type === 'DirectPartner') {
			sponsors = (await query.eq('isDirectPartner', true).find()).items;
		} else {
			sponsors = (await query.find()).items;
		}

		const items = sponsors.map((sponsor) => ({
			id: sponsor._id,
			name: sponsor.data?.title,
			isMainSponsor: sponsor.data?.isMainSponsor,
			isDirectPartner: sponsor.data?.isDirectPartner,
			...(sponsor.data?.logo && { logo: formateImageURL(sponsor.data?.logo) }),
			...(sponsor.data?.siteUrl && { url: sponsor.data?.siteUrl }),
		}));

		return items;
	},
});

export const getDonations_query = (wixClient: WixClient) => ({
	queryKey: ['getDonations'],
	queryFn: async () => {
		const { items: results } = await wixClient.items
			.queryDataItems({
				dataCollectionId: 'Donations',
			})
			.find();

		const items = results.map((donation) => ({
			id: donation._id,
			name: donation.data?.title,
			amount: new Intl.NumberFormat('pt-PT', {
				style: 'currency',
				currency: 'EUR',
			}).format(donation.data?.amount),
			date: donation.data?._createdDate.$date,
		}));

		return items;
	},
});

export const getSponsorsPlaces_query = (wixClient: WixClient) => ({
	queryKey: ['getSponsorsPlaces'],
	queryFn: async () => {
		const { items: results } = await wixClient.items
			.queryDataItems({
				dataCollectionId: 'SponsorPlaces',
				includeReferencedItems: ['Sponsors_sponsorPlaces'],
			})
			.find();

		const items = results.map((place) => ({
			id: place._id,
			name: place.data?.title,
			amount: place.data?.amount,
			available: place.data?.amount - place.data?.Sponsors_sponsorPlaces.length,
			price: new Intl.NumberFormat('pt-PT', {
				style: 'currency',
				currency: 'EUR',
			}).format(place.data?.price),
			sponsoredBy: place.data?.Sponsors_sponsorPlaces.map((sponsor: Record<string, any> | null) => ({
				id: sponsor?._id,
				name: sponsor?.title,
				...(sponsor?.logo && { logo: formateImageURL(sponsor?.logo) }),
			})),
		}));

		return items;
	},
});

const formateImageURL = (url: string) => {
	const newStr = url.replace('wix:image://v1/', 'https://static.wixstatic.com/media/');

	if (newStr.indexOf('.png') >= 0) {
		const newStr2 = newStr.slice(0, newStr.indexOf('.png') + 4);
		return newStr2;
	} else if (newStr.indexOf('.jpeg') >= 0) {
		const newStr2 = newStr.slice(0, newStr.indexOf('.jpeg') + 5);
		return newStr2;
	}

	return '';
};

const mapProducts = (products: any[], totalCount: number | undefined) => {
	let products_prices: Array<Product> = [];

	// map products
	products.forEach((product) => {
		products_prices.push(mapSingleProduct(product));
	});

	return { products: products_prices, totalCount };
};

const mapSingleProduct = (product: any): Product => {
	const images = [product.media.mainMedia, ...product.media.items]
		.map((value) => value.image.url)
		.filter((value, index, array) => array.indexOf(value) === index);

	return {
		id: product.id,
		name: product.name,
		description: product.description,
		price: product.priceData.formatted.price,
		discountedPrice: product.priceData.formatted.discountedPrice,
		images,
		status: product.stock.inStock
			? product.stock.quantity === 1
				? 'Almost Out Of Stock'
				: 'In Stock'
			: 'Out Of Stock',
		statusColor: product.stock.inStock
			? product.stock.quantity === 1
				? 'warning'
				: 'success'
			: 'danger',
		quantity: isNaN(product.stock.quantity) ? 1 : product.stock.quantity,
		discount: product.discount.value,
		inStock: product.stock.inStock,
		ribbons: product.ribbons,
		sku: product.sku,
		options: product.productOptions,
		variants: product.variants,
		filters: '',
	};
};

export const getFilteredProducts_Query = (
	wixClient: WixClient,
	mainCategory: string,
	categories: {
		label: string;
		ids: (string | null | undefined)[];
	}[],
	limit: number,
	max: number,
	min: number,
	offset: number,
	searchTerm: string,
	sort: number,
) => ({
	queryKey: [
		'getFilteredProducts',
		{
			mainCategory,
			categories,
			itemsPerPage: limit,
			max,
			min,
			range: [offset, limit + offset - 1],
			searchTerm,
			sort,
		},
	],
	queryFn: async () => {
		let sortBy: string = '';
		if (sort === SortTypes.NUM_ASC) {
			sortBy = '[{ "price": "asc" }, {"name": "asc" }]';
		} else if (sort === SortTypes.NUM_DSC) {
			sortBy = '[{ "price": "desc"}, {"name": "asc" }]';
		} else if (sort === SortTypes.STR_ASC) {
			sortBy = '[{ "name": "asc" }]';
		} else if (sort === SortTypes.STR_DSC) {
			sortBy = '[{ "name": "desc" }]';
		}

		const res = await wixClient.fetch('https://www.wixapis.com/stores-reader/v1/products/query', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: {
					paging: {
						limit,
						offset,
					},
					filter: JSON.stringify({
						...(searchTerm !== '' && { name: { $startsWith: searchTerm } }),
						...(mainCategory && { collectionIds: { $in: mainCategory } }),
						$and: [
							...categories.map((cat) => ({
								collectionIds: {
									$in: cat.ids,
								},
							})),
							{ price: { $gte: min } },
							{ price: { $lte: max } },
						],
					}),
					sort: sortBy,
				},
				includeVariants: true,
				includeHiddenProducts: false,
				includeMerchantSpecificData: false,
			}),
		});

		return res
			.json()
			.then((res) => {
				return mapProducts(res.products, res.totalResults);
			})
			.catch((err) => {
				return { products: [], totalCount: 0 };
			});
	},
});

export const getTOPTenCollections_Query = (wixClient: WixClient) => ({
	queryKey: ['getTOPTenCollections'],
	queryFn: async () => {
		const categories: any[] = [];
		const { totalCount, items } = await wixClient.collections.queryCollections().limit(100).find();

		if (totalCount && totalCount > 0 && items.length > 0) {
			categories.push(...items);

			do {
				const { items: it } = await wixClient.collections
					.queryCollections()
					.skip(categories.length)
					.limit(100)
					.find();

				categories.push(...it);
			} while (categories.length < totalCount);
		}

		return {
			items: categories
				.sort((a, b) => (a.numberOfProducts < b.numberOfProducts ? 1 : -1))
				.slice(1, 11)
				.sort((a, b) => (a.numberOfProducts < b.numberOfProducts ? 1 : -1)),
		};
	},
});

export const getFiltersByName_Query = (
	wixClient: WixClient,
	productFilters: { label: string; options: { label: string; order: number }[] }[],
) => ({
	queryKey: ['getFiltersByName', productFilters],
	queryFn: async () => {
		const allFilters: string[] = productFilters
			.map((filter) => filter.options.map((option) => option.label))
			.flat();

		const { items: filters } = await wixClient.collections
			.queryCollections()
			.hasSome('name', allFilters)
			.find()
			.then((res) => ({
				items: productFilters.map((filter) => ({
					label: filter.label,
					options: res.items
						.reduce(
							(
								acc: {
									label: string | null | undefined;
									id: string | null | undefined;
									order: number;
								}[],
								curr,
							) => {
								const found = filter.options.find((option) => option.label === curr.name);

								if (found) {
									acc.push({ label: curr.name, id: curr._id, order: found.order });
								}

								return acc;
							},
							[],
						)
						.sort((a, b) => (a.order > b.order ? 1 : -1)),
				})),
			}));

		return filters;
	},
});

/// CMS
// Contacts
export const getContact_query = (wixClient: WixClient) => ({
	queryKey: ['getContact'],
	queryFn: async () => {
		const { items: results } = await wixClient.items
			.queryDataItems({ dataCollectionId: 'Contacts' })
			.find();

		return {
			id: results[0]._id,
			name: results[0].data?.title,
			phone: results[0].data?.telephone,
			email: results[0].data?.email,
			geralEmail: results[0].data?.geralEmail,
			address: results[0].data?.address,
			addressLink: results[0].data?.addressLink,
		};
	},
});

// Events
export const getEventById_query = (wixClient: WixClient, id: string) => ({
	queryKey: ['getEventById', id],
	queryFn: async () => {
		const { data } = await wixClient.items.getDataItem(id, { dataCollectionId: 'Events' });

		return {
			date: new Date(data?.date.$date).toLocaleDateString('default', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
			}),
			headline: data?.title,
			description: data?.description,
			mainImage: formateImageURL(data?.flyer),
			images: data?.documents?.map((image: string) => formateImageURL(image)),
		};
	},
});

/// Auth
export const generateVisitorTokens = async (wixClient: WixClient) => {
	const visitorTokens = await wixClient.auth.generateVisitorTokens();
	Cookies.set('wix_refreshToken', JSON.stringify(visitorTokens.refreshToken), { expires: 2 });
	wixClient.auth.setTokens(visitorTokens);
};
export const generateMemberTokens = async (wixClient: WixClient) => {
	const oauthRedirectData = JSON.parse(localStorage.getItem('oauthRedirectData') || 'null');
	localStorage.removeItem('oauthRedirectData');
	const { code, state } = wixClient.auth.parseFromUrl();
	if (code && state && oauthRedirectData) {
		const tokens = await wixClient.auth.getMemberTokens(code, state, oauthRedirectData);
		Cookies.set('wix_refreshToken', JSON.stringify(tokens.refreshToken), { expires: 2 });
		wixClient.auth.setTokens(tokens);

		window.location = oauthRedirectData?.originalUri || '/';
	}
};
export const getUser_query = (wixClient: WixClient, isAuthenticated: boolean) => ({
	queryKey: ['getUser', isAuthenticated],
	queryFn: async () => {
		return {
			user: isAuthenticated
				? (
						await wixClient.members.getCurrentMember({
							fieldSet: members.Set.FULL,
						})
					).member
				: undefined,
		};
	},
});

/// MAP OBJECTS FUNCTIONS

/**
 * Maped array for the games returned from database
 *
 * @param games items.DataItem[]
 * @returns return all games
 */
const mapGames = (games: items.DataItem[]): Game[] => {
	return games.map((game) => ({
		id: game.data?._id,
		date: game.data?.date.$date,
		dateFormatted: {
			time: new Date(game.data?.date.$date).toLocaleString('default', {
				hour: 'numeric',
				minute: '2-digit',
			}),
			date: new Date(game.data?.date.$date).toLocaleString('default', {
				day: 'numeric',
				month: 'long',
			}),
		},
		isFriendly: game.data?.isFriendly,
		isMainEvent: game.data?.isMainEvent,
		scoreTeam1: game.data?.scoreTeam1 >= 0 ? game.data?.scoreTeam1 : '',
		scoreTeam2: game.data?.scoreTeam2 >= 0 ? game.data?.scoreTeam2 : '',
		team1: {
			id: game.data?.teamId1?._id || '',
			title: game.data?.teamId1?.title || '',
			image: game.data?.teamId1?.image
				? formateImageURL(game.data?.teamId1?.image)
				: CLUB_PLACEHOLDER_IMG,
			isHouseTeam: !!game.data?.teamId1.isHouseTeam,
		},
		team2: {
			id: game.data?.teamId2?._id || '',
			title:
				game.data?.teamId2?.title ||
				game?.data?.userName ||
				`${game?.data?.registeredUser.nickname} Team` ||
				'',
			image: game.data?.teamId2?.image
				? formateImageURL(game.data?.teamId2?.image)
				: CLUB_PLACEHOLDER_IMG,
			isHouseTeam: !!game.data?.teamId2.isHouseTeam,
		},
	}));
};
