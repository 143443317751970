import { FC, ReactNode, SetStateAction, createContext, useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { OAuthStrategy, createClient } from '@wix/sdk-react';
import { products, collections } from '@wix/stores';
import { collections as dataCollections, items } from '@wix/data';
import { members, authentication, badges } from '@wix/members';
import { files } from '@wix/media';
import { contacts } from '@wix/crm';
import Cookies from 'js-cookie';
import { generateMemberTokens, generateVisitorTokens, getUser_query } from '../api/wix-service';
import { User } from '../utils';

type WixProviderProps = {
	children: ReactNode;
};

const refreshToken = JSON.parse(Cookies.get('wix_refreshToken') || '{}');
const wixClient = createClient({
	modules: {
		products,
		collections,
		members,
		authentication,
		badges,
		dataCollections,
		items,
		files,
		contacts,
	},
	auth: OAuthStrategy({
		clientId: '3df0e7f9-34a7-4023-9dde-b78af48606e9',
		tokens: { refreshToken, accessToken: { value: '', expiresAt: 0 } },
	}),
});

export type WixClient = typeof wixClient;

type WixContextProps = {
	wixClient: WixClient;
	isAuthenticated: boolean;
	setUser: (value: SetStateAction<User | undefined>) => void;
	user?: User;
	login?: () => Promise<void>;
	logout?: () => Promise<void>;
};

const WixContext = createContext<WixContextProps>({ wixClient, isAuthenticated: false, setUser: () => {} });

export const WixProvider: FC<WixProviderProps> = ({ children }) => {
	const isAuthenticated = wixClient.auth.loggedIn();
	const { data } = useQuery(getUser_query(wixClient, isAuthenticated));
	const [user, setUser] = useState<User | undefined>(undefined);

	const handleUser = (user: SetStateAction<User | undefined>) => setUser(user);

	// Cookies for Visitors
	if (!Cookies.get('wix_refreshToken')) {
		generateVisitorTokens(wixClient);
	} else {
		// Cookies for members
		generateMemberTokens(wixClient);
	}

	return (
		<WixContext.Provider
			value={{
				wixClient,
				isAuthenticated,
				user: {
					id: user?.id || data?.user?._id || '',
					picture_url: user?.picture_url || data?.user?.profile?.photo?.url || '',
					first_name: user?.first_name || data?.user?.contact?.firstName || '',
					last_name: user?.last_name || data?.user?.contact?.lastName || '',
					full_name: user?.full_name || data?.user?.profile?.nickname || '',
					email: user?.email || data?.user?.loginEmail || '',
					email_verified: user?.email_verified || data?.user?.loginEmailVerified || false,
					phone_number:
						user?.phone_number ||
						parseInt(data?.user?.contact?.phones?.[0]?.replaceAll(' ', '') || '') ||
						undefined,
					phone_number_verified: user?.phone_number_verified || false,
					locale: user?.locale || '',
					addresses: user?.addresses || [],
					identities: user?.identities || [],
				},
				setUser: handleUser,
				login: async () => {
					const loginRequestData = wixClient.auth.generateOAuthData(
						window.location.href,
						window.location.href,
					);
					localStorage.setItem('oauthRedirectData', JSON.stringify(loginRequestData));

					const { authUrl } = await wixClient.auth.getAuthUrl(loginRequestData);
					window.location.href = authUrl;
				},
				logout: async () => {
					Cookies.remove('wix_refreshToken');
					const { logoutUrl } = await wixClient.auth.logout(window.location.href);
					window.location.href = logoutUrl;
				},
			}}
		>
			{children}
		</WixContext.Provider>
	);
};

export const useWix = () => useContext(WixContext);
