import { useParams } from 'react-router-dom';

import { Col, Container, Image, Row, Spinner } from 'react-bootstrap';

import { useQuery } from '@tanstack/react-query';

import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { Footer, Header, LayoutSideMenu } from '../../layout';

import { getEventById_query } from '../../api/wix-service';

import { useWix } from '../../providers/wix-provider';

const EventDetailPage = () => {
	const params = useParams();
	const { wixClient } = useWix();
	const { data: event, isLoading } = useQuery(getEventById_query(wixClient, params.id || ''));

	return (
		<LayoutSideMenu header={<Header />} footer={<Footer />}>
			<Container fluid>
				<Row>
					<Col md={3}>
						<Image src={event?.mainImage} className="w-100" />
					</Col>
					<Col md={9}>
						<Container className="px-5 d-flex h-100 align-items-center text-center">
							<h6>{event?.headline}</h6>
						</Container>
					</Col>
				</Row>
			</Container>
			<Container fluid className="mt-5">
				{isLoading ? (
					<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
				) : event?.images?.length > 0 ? (
					<ResponsiveMasonry columnsCountBreakPoints={{ 768: 1, 769: 7 }}>
						<Masonry gutter="16px">
							{event?.images.map((image: string, i: number) => (
								<Image
									key={i}
									src={image}
									className="w-100 rounded-3 object-fit-cover"
									style={{ height: i % 2 ? 200 : (i % 3 || i % 5) === 1 ? 250 : 400 }}
								/>
							))}
						</Masonry>
					</ResponsiveMasonry>
				) : null}
			</Container>
		</LayoutSideMenu>
	);
};

export default EventDetailPage;
