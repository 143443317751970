import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ButtonGroup, Col, Container, Image, Row, Spinner, ToggleButton } from 'react-bootstrap';
import { Calendar as ReactCalendar } from 'react-calendar';
import { Footer, Header, LayoutSideMenu } from '../../layout';
import { Icon } from '../../components';
import { getGames_query } from '../../api/wix-service';
import { useWix } from '../../providers/wix-provider';
import 'react-calendar/dist/Calendar.css';
import TableItem from './table-item';

const Calendar = () => {
	const { wixClient } = useWix();
	const [selectedView, setSelectedView] = useState('calendar');
	const { data: games, isLoading } = useQuery(getGames_query(wixClient, 'All'));

	return (
		<LayoutSideMenu header={<Header />} footer={<Footer />}>
			<Container className="px-0 mb-4 text-end px-md-5">
				<ButtonGroup>
					<ToggleButton
						id={`radio-${0}`}
						type="radio"
						name="radio"
						value="calendar"
						checked={'calendar' === selectedView}
						onChange={(e) => setSelectedView(e.currentTarget.value)}
					>
						<Icon name="calendar3" />
					</ToggleButton>
					<ToggleButton
						id={`radio-${1}`}
						type="radio"
						name="radio"
						value="list"
						checked={'list' === selectedView}
						onChange={(e) => setSelectedView(e.currentTarget.value)}
					>
						<Icon name="list" />
					</ToggleButton>
				</ButtonGroup>
			</Container>
			{isLoading ? (
				<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
			) : (
				<>
					{selectedView === 'calendar' && (
						<ReactCalendar
							className="bg-text-dark"
							calendarType="gregory"
							tileContent={({ activeStartDate, date, view }) => {
								if (view === 'month') {
									return games?.map((game: any) =>
										new Date(game.date).toDateString() ===
											new Date(date).toDateString() && game.isMainEvent ? (
											<div
												className="d-flex justify-content-center flex-column mt-3"
												key={date.toDateString()}
											>
												<Row xs={1} sm={3} className="g-0 align-items-center">
													<Col>
														<Image
															src={game.team1.image}
															className="w-100"
															alt={game.team1.title}
														/>
													</Col>
													<Col className="text-center">{`${game.scoreTeam1} - ${game.scoreTeam2}`}</Col>
													<Col>
														<Image
															src={game.team2.image}
															className="w-100"
															alt={game.team2.title}
														/>
													</Col>
												</Row>
												{game.isFriendly && (
													<h6 className="text-center mb-0 mt-2">Amigável</h6>
												)}
											</div>
										) : null,
									);
								}
								return null;
							}}
						/>
					)}
					{selectedView === 'list' &&
						(games && games.length > 0 ? (
							<Container className="px-0 px-md-5">
								<table className="game-list">
									<tbody>
										{games?.map(
											(game: any, i: number, arr: any[]) =>
												i % 2 === 0 &&
												(i + 1) % 2 === 1 && (
													<tr key={i}>
														{i % 2 === 0 && game.isMainEvent && (
															<TableItem game={game} />
														)}
														{(i + 1) % 2 === 1 &&
															arr[i + 1] &&
															game.isMainEvent && (
																<TableItem game={arr[i + 1]} />
															)}
													</tr>
												),
										)}
									</tbody>
								</table>
							</Container>
						) : (
							<h3 className="text-center">Sem jogos agendados!</h3>
						))}
				</>
			)}
		</LayoutSideMenu>
	);
};

export default Calendar;
