import { Link } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import classNames from 'classnames';

import { Card, Col, Container, Image, OverlayTrigger, ProgressBar, Row, Tooltip } from 'react-bootstrap';

import { LayoutSideMenu, Header, Footer } from '../../layout';
import RaffleSection from '../../components/raffle-section/RaffleSection';

import { getContact_query, getGames_query, getInvestments_query } from '../../api/wix-service';
import { useWix } from '../../providers/wix-provider';

import { Game } from '../../utils';

import BACK_IMG from '../../assets/estadio.png';
import EVENT_IMG from '../../assets/event.png';
import EVENT1_IMG from '../../assets/event1.png';

const HomePage = () => {
	const { wixClient } = useWix();
	const { data: games } = useQuery(getGames_query(wixClient, ['Next', 'Played']));
	const { data: investments } = useQuery(getInvestments_query(wixClient));
	const { data: contact } = useQuery(getContact_query(wixClient));

	return (
		<LayoutSideMenu hasAnimatedHeader header={<Header hasAnimatedHeader />} footer={<Footer />}>
			<div
				className="p-3 p-md-4 text-center bg-black bg-opacity-50"
				style={{
					backgroundImage: `url(${BACK_IMG})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundBlendMode: 'overlay',
				}}
			>
				<div className="p-lg-5 mx-auto my-5 text-white">
					<h1 className="display-3 fw-bold">Próximo Jogo</h1>
					<Container fluid className="my-4">
						{games?.map((game: any) =>
							game['Next']?.games.map((item: Game, i: number) => (
								<Container fluid className="p-0" key={i}>
									{item.isFriendly && <h5 className="fw-normal mb-3">Amigável</h5>}
									<Row xs={1} md={3} className="align-items-center justify-content-center">
										<Col>
											<Image
												src={item.team1.image}
												width="100%"
												style={{ maxWidth: 200 }}
												alt={item.team1.title}
											/>
											<h5 className="mt-4">{item.team1.title}</h5>
										</Col>
										<Col md="auto">
											<h3>{item.dateFormatted.time}</h3>
											<h3>{item.dateFormatted.date}</h3>
										</Col>
										<Col>
											<Image
												src={item.team2.image}
												width="100%"
												style={{ maxWidth: 200 }}
												alt={item.team2.title}
											/>
											<h5 className="mt-4">{item.team2.title}</h5>
										</Col>
									</Row>
								</Container>
							)),
						)}
					</Container>
					<h5 className="fw-normal mb-3">Vem Assistir e Apoiar a nossa equipa!</h5>
				</div>
			</div>
			<Container fluid className="p-0 d-flex overflow-hidden overflow-x-auto">
				{games?.map((game: any) =>
					game['Played']?.games.map((item: Game, i: number) => (
						<div
							className={classNames(
								'd-flex justify-content-center flex-column p-3 bg-body-secondary border-primary border',
								{
									'border-start-0': i > 0,
								},
							)}
							key={i}
						>
							<div className="d-flex gap-3 align-items-center">
								<Image src={item.team1.image} width={50} alt={item.team1.title} />
								<div className="text-nowrap">{`${item.scoreTeam1} - ${item.scoreTeam2}`}</div>
								<Image src={item.team2.image} width={50} alt={item.team2.title} />
							</div>
							{item.isFriendly && <h6 className="text-center mb-0">Amigável</h6>}
						</div>
					)),
				)}
			</Container>
			<Container fluid className="mt-4">
				<Row xs={1} lg={2} xxl={3} className="g-4 news-list">
					<Col>
						<RaffleSection contact={contact?.phone} date={new Date('07/04/2024')} />
					</Col>
					<Col>
						<Container fluid className="h-100 p-0">
							<div className="p-3 p-md-5 text-center rounded-1 h-100">
								<div className="my-3 p-3">
									<h2 className="display-5">Dia de Evento!!</h2>
								</div>
								<Link
									to={`event/c10baa17-de89-45c3-82a0-0a1124e76f43`}
									className="bg-body shadow-sm mx-auto p-4 d-block"
									style={{ width: '80%', borderRadius: '21px 21px 0 0' }}
								>
									<Image src={EVENT1_IMG} alt="event day" className="w-100" />
								</Link>
							</div>
						</Container>
					</Col>
					<Col>
						<Container fluid className="h-100 p-0">
							<div className="p-3 p-md-5 text-center rounded-1 h-100">
								<div className="my-3 p-3">
									<h2 className="display-5">Dia de Evento!!</h2>
								</div>
								<Link
									to={`event/912d49af-e447-4327-985e-2d2aac4f0630`}
									className="bg-body shadow-sm mx-auto p-4 d-block"
									style={{ width: '80%', borderRadius: '21px 21px 0 0' }}
								>
									<Image src={EVENT_IMG} alt="event day" className="w-100" />
								</Link>
							</div>
						</Container>
					</Col>
					<Col xxl={8}>
						<Container fluid className="h-100 p-0">
							<div className="p-3 p-md-5 text-center rounded-1 h-100">
								<div className="my-3 py-3">
									<h2 className="display-5">Objetivos 2024</h2>
									<p className="lead">Faça parte da nossa iniciativa.</p>
								</div>
								<Container fluid>
									<Container fluid className="p-0">
										<Row xs={1} md={2} xxl={3} className="investments-list g-4">
											{investments?.map((item, i) => (
												<Col key={i}>
													<Card>
														<Card.Body>
															<Card.Title>{`${item.order}º ${item.title}`}</Card.Title>
															<Card.Text>{`Orçamento: ${item.budget}`}</Card.Text>
															<OverlayTrigger
																placement="bottom"
																delay={{ show: 250, hide: 400 }}
																overlay={(props) => (
																	<Tooltip id="progress-tooltip" {...props}>
																		{item.received}
																	</Tooltip>
																)}
															>
																<ProgressBar
																	now={item.progress}
																	{...(item.progress === 100 && {
																		variant: 'success',
																	})}
																/>
															</OverlayTrigger>
														</Card.Body>
													</Card>
												</Col>
											))}
										</Row>
										<Link
											role="button"
											tabIndex={0}
											to="help/donate"
											className="mt-5 btn btn-primary"
										>
											Saber mais...
										</Link>
									</Container>
								</Container>
							</div>
						</Container>
					</Col>
				</Row>
			</Container>
		</LayoutSideMenu>
	);
};

export default HomePage;
