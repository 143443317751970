import { FC, ReactNode, createContext, useContext, useState } from 'react';
import { Product } from '../utils';

type Cart = {
	cart: Array<Product>;
	setCart: (cart: Array<Product>) => void;
};

type Props = {
	children: ReactNode;
};

const CartContext = createContext<Cart>({ cart: [], setCart: (cart) => cart });

export const CartProvider: FC<Props> = ({ children = null }) => {
	const [cart, setCart] = useState<Array<Product>>([]);

	return (
		<CartContext.Provider value={{ cart, setCart: (cart) => setCart(cart) }}>
			{children}
		</CartContext.Provider>
	);
};

export const useCart = () => useContext(CartContext);
