import { FC, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Nav, NavDropdown, NavItem } from 'react-bootstrap';
import BrandLogo from './BrandLogo';
import HeaderCart from './HeaderCart';
import { useTheme } from '../../providers/theme-provider';

type MenuProps = {
	onClickSubPage: () => void;
};

const Dropdown = ({ onClickSubPage, item }: any) => {
	const location = useLocation();
	const { breakpoint } = useTheme();
	const [show, setShow] = useState(false);

	return (
		<NavDropdown
			as="li"
			title={item.label}
			id="navbarScrollingDropdown"
			{...(location.pathname.includes(item.path) && {
				active: true,
			})}
			show={show || (location.pathname.includes(item.path) && (breakpoint === 'sm' || !breakpoint))}
			{...(breakpoint !== 'sm' && breakpoint && { onMouseEnter: () => setShow(!show) })}
			{...(breakpoint !== 'sm' && breakpoint && { onMouseLeave: () => setShow(false) })}
			{...((breakpoint === 'sm' || !breakpoint) && { onClick: () => setShow(!show) })}
		>
			{item.subPages.map((subItem: any, j: number) => (
				<NavDropdown.Item
					as={NavLink}
					to={`/${item.path}/${subItem.path}`}
					key={j}
					onClick={onClickSubPage}
				>
					{subItem.label}
				</NavDropdown.Item>
			))}
		</NavDropdown>
	);
};
const Menu: FC<MenuProps> = ({ onClickSubPage }) => {
	const MENUS: { label: string; path: string; subPages: { label: string; path: string }[] }[] = [
		{
			label: 'Suporte-nos',
			path: 'help',
			subPages: [
				{ label: 'Patrocinadores', path: 'sponsors' },
				{ label: 'Doar', path: 'donate' },
			],
		},
		{
			label: 'A nossa equipa',
			path: 'our-team',
			subPages: [
				{ label: 'Calendário', path: 'calendar' },
				//{ label: 'Plantel', path: 'squad' },
				//{ label: 'Escolas', path: 'schools' },
			],
		},
		// {
		// 	label: 'Campo',
		// 	path: 'soccer-field',
		// 	subPages: [{ label: 'Reservar', path: 'reservation' }],
		// },
		//{ label: 'Loja', path: 'store', subPages: [] },
	];

	return (
		<>
			<NavItem as="li" className="d-none d-md-block">
				<Nav.Link as={Link} to="/">
					<BrandLogo />
				</Nav.Link>
			</NavItem>
			<div className="d-block d-md-flex gap-md-4">
				{MENUS.map((item, i) => {
					return item.subPages.length > 0 ? (
						<Dropdown onClickSubPage={onClickSubPage} item={item} key={i} />
					) : (
						<NavItem as="li" key={i}>
							<Nav.Link as={NavLink} to={`/${item.path}`}>
								{item.label}
							</Nav.Link>
						</NavItem>
					);
				})}

				{/* <HeaderCart /> */}
			</div>
		</>
	);
};

export default Menu;
