import { createBrowserRouter } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import {
	AboutUs,
	Calendar,
	Cart,
	Checkout,
	Donate,
	EventDetailPage,
	HomePage,
	Login,
	NotFound,
	ProductDetail,
	Profile,
	Reservation,
	Sponsors,
	Squad,
	Store,
	Success,
	TermsAndConditions,
	WixCheckout,
} from '../pages';
import App from '../app';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

export const router = createBrowserRouter([
	{
		path: '/',
		errorElement: <NotFound />,
		element: <App />,
		children: [
			{
				index: true,
				element: <HomePage />,
			},
			{
				path: 'help',
				children: [
					{
						path: 'sponsors',
						element: <Sponsors />,
					},
					{
						path: 'donate',
						element: <Donate />,
					},
				],
			},
			{
				path: 'our-team',
				children: [
					{
						path: 'calendar',
						element: <Calendar />,
					},
					// {
					// 	path: 'squad',
					// 	element: <Squad />,
					// },
					// {
					// 	path: 'schools',
					// 	element: <Profile />,
					// },
				],
			},
			// {
			// 	path: 'soccer-field',
			// 	children: [
			// 		{
			// 			path: 'reservation',
			// 			element: <Reservation />,
			// 		},
			// 	],
			// },
			// {
			// 	path: 'store',
			// 	element: <Store />,
			// },
			{
				path: 'about-us',
				element: <AboutUs />,
			},
			{
				path: 'event',
				children: [
					{
						path: ':id',
						element: <EventDetailPage />,
					},
				],
			},
			// {
			// 	path: 'products/:category?',
			// 	element: <Products />,
			// },
			// {
			// 	path: 'product/:id',
			// 	element: <ProductDetail />,
			// },
			// {
			// 	path: 'login',
			// 	element: <Login />,
			// },
			// {
			// 	path: 'config',
			// 	element: <ConfigPage />,
			// },
			// {
			// 	path: 'profile',
			// 	element: <Profile />,
			// },
			// {
			// 	path: 'checkout',
			// 	element: <Checkout />,
			// },
			// {
			// 	path: 'wix-checkout',
			// 	element: <WixCheckout />,
			// },
			// {
			// 	path: 'cart',
			// 	element: <Cart />,
			// },
			// {
			// 	path: 'terms-and-conditions',
			// 	element: <TermsAndConditions />,
			// },
			// {
			// 	path: 'success',
			// 	element: <Success />,
			// },
		],
	},
]);
