import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Icon, Section } from '../../../components';
import { Formik } from 'formik';
import { object, string } from 'yup';
import ConfirmationModal from '../ConfirmationModal';
import { useTranslator } from '../../../providers/translator-provider';
import { useWix } from '../../../providers/wix-provider';

const UserForm = () => {
	const { t } = useTranslator();
	const { user } = useWix();
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	const validationSchema = object().shape({
		name: string().required('Name is required.'),
		lastName: string().required('Last Name is required.'),
		email: string().email('Not an email.').required('Email is required.'),
		phoneNumber: string()
			.min(9, 'Phone Number must be at least 9 characters')
			.max(9, 'Phone Number must be at most 9 characters'),
	});

	const updateUserMutation = useMutation({
		mutationFn: async (values: any) => {
			// const newUser = await updateUser(values);
			// setUser(newUser);
		},
	});

	const handleOnHideConfimationModal = async (confirmation: 'YES' | 'NO', values: any) => {
		// if (confirmation === 'YES') {
		// 	const newUser = await updateUser(values);
		// 	if (newUser) {
		// 		await loginWithRedirect({
		// 			appState: {
		// 				returnTo: '/profile',
		// 			},
		// 		});
		// 	}
		// }
		// setShowConfirmationModal(false);
	};

	const updateUser = async (values: any) => {
		// return await updateUserProfile(
		// 	user.id,
		// 	{
		// 		...(values.name !== user.first_name && { given_name: values.name }),
		// 		...(values.lastName !== user.last_name && { family_name: values.lastName }),
		// 		...(values.email !== user.email && { email: values.email }),
		// 		user_metadata: {
		// 			phone_number: values.phoneNumber,
		// 		},
		// 	},
		// 	token
		// );
	};

	const handleOnSubmit = (values: any) => {
		if (values.email !== user?.email) {
			setShowConfirmationModal(true);
		} else {
			updateUserMutation.mutate(values);
		}
	};

	const isSocial = () => (user?.identities.find((val: any) => val.isSocial) ? true : false);

	return (
		<Section noStyle>
			{/* {!isLoading ? (
				<Formik
					validationSchema={validationSchema}
					onSubmit={handleOnSubmit}
					initialValues={{
						name: user.first_name,
						lastName: user.last_name,
						email: user.email,
						phoneNumber: user.phone_number,
					}}
				>
					{({ handleSubmit, handleChange, values, touched, errors }) => (
						<>
							<ConfirmationModal
								show={showConfirmationModal}
								bodyText={
									<div className="text-center">
										{t('since_you_are_updating_the')} <b>{t('email')}</b>
										{t('you_will_be_redirected_to_login_page_to_login_again')} <br />
										<br />
										{t('are_you_sure_that_you_whant_to_continue')}
									</div>
								}
								onHide={(confirmation: 'YES' | 'NO') =>
									handleOnHideConfimationModal(confirmation, values)
								}
							/>
							<Form noValidate onSubmit={handleSubmit}>
								<Container fluid>
									<Row>
										<Col>
											<Form.Group className="mb-3" controlId="name">
												<Form.Label>{t('name')}</Form.Label>
												<Form.Control
													type="text"
													placeholder={t('name')}
													name="name"
													value={values.name}
													onChange={handleChange}
													isValid={touched.name && !errors.name}
													isInvalid={!!errors.name}
													readOnly={isSocial()}
													disabled={isSocial()}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.name}
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
										<Col>
											<Form.Group className="mb-3" controlId="lastname">
												<Form.Label>{t('last_name')}</Form.Label>
												<Form.Control
													type="text"
													placeholder={t('last_name')}
													name="lastname"
													value={values.lastName}
													onChange={handleChange}
													isValid={touched.lastName && !errors.lastName}
													isInvalid={!!errors.lastName}
													readOnly={isSocial()}
													disabled={isSocial()}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.lastName}
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
									</Row>
								</Container>
								<Container fluid>
									<Row>
										<Col>
											<Form.Group className="mb-3" controlId="email">
												<Form.Label>{t('email')}</Form.Label>
												<Form.Control
													type="email"
													placeholder={t('email')}
													name="email"
													value={values.email}
													onChange={handleChange}
													isValid={touched.email && !errors.email}
													isInvalid={!!errors.email}
													readOnly={isSocial()}
													disabled={isSocial()}
												/>
												{errors.email ? (
													<Form.Control.Feedback type="invalid">
														{errors.email}
													</Form.Control.Feedback>
												) : (
													isSocial() && (
														<Form.Control.Feedback
															type="invalid"
															className="d-block"
														>
															{t('cant_edit_email')}
														</Form.Control.Feedback>
													)
												)}
												{user.email_verified ? (
													<a href="#!" className="text-success small">
														<Icon name="check2-circle" className="me-1" />
														{t('verified')}
													</a>
												) : (
													<a href="#!" className="text-danger small">
														<Icon name="x-circle" className="me-1" />
														{t('not_verified')}
													</a>
												)}
											</Form.Group>
										</Col>
										<Col>
											<Form.Group className="mb-3" controlId="phoneNumber">
												<Form.Label>{t('phone_number')}</Form.Label>
												<Form.Control
													type="number"
													placeholder={t('phone_number')}
													name="phoneNumber"
													value={values.phoneNumber}
													onChange={handleChange}
													isValid={touched.phoneNumber && !errors.phoneNumber}
													isInvalid={!!errors.phoneNumber}
												/>
												{errors.phoneNumber ? (
													<Form.Control.Feedback type="invalid">
														{errors.phoneNumber}
													</Form.Control.Feedback>
												) : user.phone_number_verified ? (
													<a href="#!" className="text-success small">
														<Icon name="check2-circle" className="me-1" />
														{t('verified')}
													</a>
												) : (
													<a href="#!" className="text-danger small">
														<Icon name="x-circle" className="me-1" />
														{t('not_verified')}
													</a>
												)}
											</Form.Group>
										</Col>
									</Row>
								</Container>
								<Container fluid className="text-end">
									<Button type="submit">
										{updateUserMutation.isLoading && (
											<Spinner
												as="span"
												animation="border"
												size="sm"
												role="status"
												aria-hidden="true"
											/>
										)}
										{t('save')}
									</Button>
								</Container>
							</Form>
						</>
					)}
				</Formik>
			) : (
				<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
			)} */}
		</Section>
	);
};

export default UserForm;
