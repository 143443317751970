import { Outlet } from 'react-router-dom';
import { CartProvider } from './providers/cart-provider';
import { TranslatorProvider } from './providers/translator-provider';
import { ThemeProvider } from './providers/theme-provider';
import { WixProvider } from './providers/wix-provider';

const App = () => {
	return (
		<ThemeProvider>
			<WixProvider>
				<CartProvider>
					<TranslatorProvider>
						<Outlet />
					</TranslatorProvider>
				</CartProvider>
			</WixProvider>
		</ThemeProvider>
	);
};

export default App;
