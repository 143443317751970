import { useQuery } from '@tanstack/react-query';
import {
	Button,
	Card,
	Col,
	Container,
	ListGroup,
	OverlayTrigger,
	ProgressBar,
	Row,
	Stack,
	Tooltip,
} from 'react-bootstrap';
import { Footer, Header, LayoutSideMenu } from '../../layout';
import { getDonations_query, getInvestments_query } from '../../api/wix-service';
import { useWix } from '../../providers/wix-provider';

const Donate = () => {
	const { wixClient } = useWix();
	const { data: investments } = useQuery(getInvestments_query(wixClient));
	const { data: donations } = useQuery(getDonations_query(wixClient));

	const handleDonation = () => {};

	return (
		<LayoutSideMenu header={<Header />} footer={<Footer />}>
			<Container fluid>
				<h2>Objetivos 2024</h2>
				<Container fluid>
					<h3>Investimentos:</h3>
					<Container fluid className="p-0">
						<Row xs={1} md={4} className="investments-list g-4">
							{investments?.map((item, i) => (
								<Col key={i}>
									<Card>
										<Card.Body>
											<Card.Title>{`${item.order}º ${item.title}`}</Card.Title>
											<Card.Text>{`Orçamento: ${item.budget}`}</Card.Text>
											<OverlayTrigger
												placement="bottom"
												delay={{ show: 250, hide: 400 }}
												overlay={(props) => (
													<Tooltip id="progress-tooltip" {...props}>
														{item.received}
													</Tooltip>
												)}
											>
												<ProgressBar
													now={item.progress}
													{...(item.progress === 100 && {
														variant: 'success',
													})}
												/>
											</OverlayTrigger>
										</Card.Body>
									</Card>
								</Col>
							))}
						</Row>
					</Container>
					{/* <Button className="mt-4" onClick={handleDonation}>
						Doar
					</Button> */}
				</Container>
			</Container>
			<Container className="mt-5 max-width m-auto text-danger text-center">
				Qualquer valor doado será aqui registado e atualizado no final de cada mês e será totalmente
				direcionado apenas e exclusivamente para os investimentos acima listados.
			</Container>
			{donations && donations.length > 0 && (
				<Container fluid className="mt-5">
					<h2>Ultimas doações</h2>
					<Container
						fluid
						className="p-0"
						style={{ overflow: 'hidden', overflowY: 'auto', maxHeight: 300 }}
					>
						<ListGroup>
							{donations?.map((donation, i) => (
								<ListGroup.Item className="d-flex" key={i}>
									<Stack
										direction="horizontal"
										gap={2}
										className="justify-content-between w-100"
									>
										<div className="d-inline-flex align-items-center">
											<h4 className="me-4 mb-0">{donation.name}</h4>
											<span className="me-4">{donation.amount}</span>
										</div>
										<span>
											{new Date(donation.date).toLocaleDateString('default', {
												day: '2-digit',
												month: 'short',
												year: 'numeric',
												hour: '2-digit',
												minute: '2-digit',
												second: '2-digit',
											})}
										</span>
									</Stack>
								</ListGroup.Item>
							))}
						</ListGroup>
					</Container>
				</Container>
			)}
		</LayoutSideMenu>
	);
};

export default Donate;
