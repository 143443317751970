import { FC, ReactNode, createContext, useContext, useState, useEffect } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en_GB from '../locales/en-GB.json';
import es_ES from '../locales/es-ES.json';
import pt_PT from '../locales/pt-PT.json';
import { useWix } from './wix-provider';

type TranslatorProps = {
	children: ReactNode;
};

const TranslatorContext = createContext<any>(null);

export const TranslatorProvider: FC<TranslatorProps> = ({ children }) => {
	const { user } = useWix();
	const [language, setLanguage] = useState<string | undefined>('pt-PT');

	i18n.use(initReactI18next) // passes i18n down to react-i18next
		.init({
			resources: {
				en: { en_GB },
				es: { es_ES },
				pt: { pt_PT },
			},
			lng: language, // if you're using a language detector, do not define the lng option
			fallbackLng: 'pt-PT',
			supportedLngs: ['en-GB', 'es-ES', 'pt-PT'],
			interpolation: {
				escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
			},
			defaultNS: language,
		});

	document.documentElement.lang = i18n.language;

	useEffect(() => {
		setLanguage(user?.locale);
	}, [user]);

	return (
		<TranslatorContext.Provider
			value={{
				changeLanguage: (lang: string) => setLanguage(lang),
				t: i18n.t,
				lang: i18n.language,
			}}
		>
			{children}
		</TranslatorContext.Provider>
	);
};

export const useTranslator = () => useContext(TranslatorContext);
