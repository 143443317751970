import { Container, Image } from 'react-bootstrap';
import classNames from 'classnames';
import { Team } from '../../utils';
import { Icon } from '../../components';

const TeamItem = ({
	team,
	date,
	score,
	className = '',
}: {
	team: Team;
	date: Date;
	score: number;
	className?: string;
}) => {
	return (
		<Container
			fluid
			className={classNames(
				'd-flex align-items-center gap-3 justify-content-between position-relative',
				className
			)}
		>
			<div className="d-flex align-items-center gap-3">
				<Image src={team.image} alt={team.title} width={24} />
				<h6 className="m-0">{team.title}</h6>
			</div>
			{new Date(date) < new Date() && (
				<>
					<span>{score}</span>
					<Icon
						name="caret-left-fill"
						className="position-absolute translate-middle-y top-50"
						color="var(--bs-primary)"
						style={{ right: '-5px' }}
					/>
				</>
			)}
		</Container>
	);
};

export default TeamItem;
