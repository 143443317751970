import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { UserInfo, Menu, BrandLogo } from '..';
import classNames from 'classnames';
import { useWix } from '../../providers/wix-provider';

type HeaderProps = {
	hasAnimatedHeader?: boolean;
};

const Header: FC<HeaderProps> = ({ hasAnimatedHeader }) => {
	const { login, isAuthenticated } = useWix();
	const [offCanvasStatus, setOffCanvasStatus] = useState(false);
	const [headerBackground, setHeaderBackground] = useState(false);

	const scrollEvent = (e: Event) => {
		if (document.documentElement.scrollTop >= 100) {
			setHeaderBackground(true);
		} else {
			setHeaderBackground(false);
		}
	};

	useEffect(() => {
		document.addEventListener('scroll', scrollEvent);

		return () => {
			document.removeEventListener('scroll', scrollEvent);
		};
	}, []);

	return (
		<Navbar
			className={classNames('header', {
				'border-bottom': (headerBackground && hasAnimatedHeader) || !hasAnimatedHeader,
				'animated-header': hasAnimatedHeader,
			})}
			fixed="top"
			expand="md"
			{...(((headerBackground && hasAnimatedHeader) || !hasAnimatedHeader) && { bg: 'light' })}
		>
			<Container>
				<Navbar.Brand as={Link} to="/" className="d-md-none">
					<BrandLogo />
				</Navbar.Brand>

				<Navbar.Toggle
					className={classNames({ 'bg-body-tertiary': !headerBackground && hasAnimatedHeader })}
					aria-controls="navbarScroll"
					onClick={() => setOffCanvasStatus(true)}
				/>

				<Navbar.Offcanvas
					id="navbarScroll"
					aria-labelledby="offcanvasNavbarLabel-expand-navbarScroll"
					placement="end"
					show={offCanvasStatus}
					onHide={() => setOffCanvasStatus(false)}
				>
					<Offcanvas.Header closeButton>
						<Offcanvas.Title id="offcanvasNavbarLabel-expand-navbarScroll">
							<BrandLogo />
						</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>
						<Nav as="ul" className="flex-grow-1 justify-content-between gap-2">
							<Menu onClickSubPage={() => setOffCanvasStatus(false)} />

							<div></div>
							{/* {!isAuthenticated ? <Button onClick={login}>Entrar</Button> : <UserInfo />} */}
						</Nav>
					</Offcanvas.Body>
				</Navbar.Offcanvas>
			</Container>
		</Navbar>
	);
};

export default Header;
