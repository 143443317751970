import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, Container, Image, ListGroup, Stack } from 'react-bootstrap';
import classNames from 'classnames';
import { Footer, Header, LayoutSideMenu } from '../../layout';
import { getContact_query, getSponsorsPlaces_query, getSponsors_query } from '../../api/wix-service';
import { Icon } from '../../components';
import { useWix } from '../../providers/wix-provider';

const Sponsors = () => {
	const { wixClient } = useWix();
	const { data: sponsors } = useQuery(getSponsors_query(wixClient, 'All'));
	const { data: places } = useQuery(getSponsorsPlaces_query(wixClient));
	const { data: contact } = useQuery(getContact_query(wixClient));
	const [showContacts, setShowContacts] = useState(false);

	return (
		<LayoutSideMenu header={<Header />} footer={<Footer />}>
			<Container fluid>
				<h3>Patrocinadores</h3>
				{sponsors
					?.sort((a, b) => (a.isMainSponsor === b.isMainSponsor ? 0 : a.isMainSponsor ? -1 : 1))
					.map((sponsor, i) => (
						<Container key={i} fluid className={classNames({ 'mt-4': i !== 0 })}>
							<Stack direction="horizontal" gap={4}>
								<Image src={sponsor.logo} alt={sponsor.name} height="100%" width={120} />
								{sponsor.isMainSponsor && <h3 className="mb-0">Patrocinador Official</h3>}
							</Stack>
						</Container>
					))}
			</Container>
			<Container fluid className="mt-5">
				<div className="d-flex justify-content-between">
					<h3>Torna-te um patrocinador</h3>
					<div className="d-flex flex-column align-items-end">
						<Button onClick={() => setShowContacts(!showContacts)}>Contactar</Button>
						{showContacts && (
							<>
								<a className="mt-3" href={`mailto:${contact?.geralEmail}`}>
									<Icon name="envelope-at-fill" className="me-2" />
									{contact?.geralEmail}
								</a>
								<a href={`tel:+351${contact?.phone}`}>
									<Icon name="telephone-fill" className="me-2" />
									+351 {contact?.phone}
								</a>
							</>
						)}
					</div>
				</div>
				<ListGroup className="mt-4">
					{places?.map((place, i) => (
						<ListGroup.Item key={i}>
							<Stack direction="horizontal" gap={5}>
								<div className="text-nowrap">
									<h4 className="mb-0">{place.name}</h4>
									<span className="small">{place.price}</span>
									<br />
									<span className="small">{`${place.available} de ${place.amount}  lugares ainda disponiveis`}</span>
									<br />
									<span className="small text-danger">Contrato por época!</span>
								</div>
								<div className="overflow-hidden">
									{place.sponsoredBy.length > 0 && <h4>Patrocinado por:</h4>}
									<div className="overflow-x-auto text-nowrap">
										{place.sponsoredBy.map(
											(sponsor: Record<string, any> | null, i: number) => (
												<Image
													key={i}
													src={sponsor?.logo}
													style={{ maxHeight: 65 }}
													alt={sponsor?.name}
												/>
											)
										)}
									</div>
								</div>
							</Stack>
						</ListGroup.Item>
					))}
				</ListGroup>
			</Container>
		</LayoutSideMenu>
	);
};

export default Sponsors;
