import { Container, Image } from 'react-bootstrap';

import { Footer, Header, LayoutSideMenu } from '../../layout';

import { Section } from '../../components';

import LOGO from '../../assets/logo.png';

const AboutUs = () => {
	return (
		<LayoutSideMenu maxWith header={<Header />} footer={<Footer />}>
			<Container fluid className="p-0 py-4">
				<Section title="Sobre nós" noStyle>
					O Clube ANDAR orgulha-se de ser um símbolo de união e paixão pelo futebol na nossa
					comunidade. Fundado com o objetivo de promover o desporto e criar laços entre os
					habitantes da nossa terra, o ANDAR construiu ao longo dos anos uma rica história de
					competição, camaradagem e sucesso. <br />
					<br />
					Após um período de pausa, estamos a reativar o clube com o mesmo entusiasmo e dedicação
					que nos caracterizou desde o início. A nossa missão é simples: trazer de volta o futebol à
					nossa terra, dando a todos a oportunidade de viver o espírito do jogo, seja em campo ou
					nas bancadas. <br />
					<br />
					No Clube ANDAR, acreditamos que o futebol é mais do que um desporto; é uma forma de vida
					que promove valores como a amizade, o trabalho em equipa e o respeito. Estamos empenhados
					em voltar a construir um clube forte, onde todos são bem-vindos a participar,
					independentemente da idade ou do talento. <br />
					<br />
					Estamos prontos para iniciar uma nova fase da nossa história e convidamos todos a
					juntar-se a nós nesta jornada. Vamos fazer do Clube ANDAR uma vez mais um orgulho da nossa
					terra!
				</Section>
				<div className="text-center mt-5">
					<Image src={LOGO} width={150} />
				</div>
			</Container>
		</LayoutSideMenu>
	);
};

export default AboutUs;
