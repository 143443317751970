import { ReactNode, FC } from 'react';
import classNames from 'classnames';
import { Card } from 'react-bootstrap';

type Props = {
	children?: ReactNode;
	title?: string;
	actions?: ReactNode;
	noStyle?: boolean;
	className?: string;
};

const Section: FC<Props> = ({
	children = null,
	title = '',
	actions = null,
	noStyle = false,
	className = '',
}) => {
	return (
		<Card
			className={classNames('section', className, {
				'no-style': noStyle,
			})}
		>
			{(title || actions) && (
				<Card.Header>
					<Card.Title>{title}</Card.Title>
					<div className="section--actions">{actions}</div>
				</Card.Header>
			)}
			<Card.Body>{children}</Card.Body>
		</Card>
	);
};

export default Section;
