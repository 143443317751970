import { Image } from 'react-bootstrap';
import logo from '../../assets/logo.png';
import { FC } from 'react';

type BrandLogoProps = {
	className?: string;
};

const BrandLogo: FC<BrandLogoProps> = ({ className }) => {
	return <Image src={logo} height={24} className={className} alt="andar" />;
};

export default BrandLogo;
