import { Container, Image } from 'react-bootstrap';

import RAFFLE_IMG from '../../assets/rifa.jpeg';

const RaffleSection = ({ contact, date }: { contact: any; date: Date }) => {
	return (
		<Container fluid className="h-100 p-0">
			<div className="p-3 p-md-5 text-center rounded-1 h-100">
				<div className="my-3 p-3">
					<h2 className="display-5">Sorteio!!</h2>
					<p className="lead">Sorteio a favor do clube ANDAR FC para reabilitação.</p>
				</div>
				<div
					className="bg-body shadow-sm mx-auto p-4"
					style={{ width: '80%', borderRadius: '21px 21px 0 0' }}
				>
					{date.getTime() > new Date().getTime() ? (
						<>
							<h4>Restam: 50 rifas</h4>
							<h6>Compra já a tua!!</h6>
						</>
					) : (
						<>
							<h4>Parabéns aos vencedores!!</h4>
							<div className="d-flex align-items-center m-auto" style={{ maxWidth: 230 }}>
								<h6 className="d-inline">1º Lugar:</h6>
								<h5 className="d-inline ms-2">Nicole</h5>
							</div>
							<div className="d-flex align-items-center m-auto" style={{ maxWidth: 230 }}>
								<h6 className="d-inline">2º Lugar:</h6>
								<h5 className="d-inline ms-2">Rita</h5>
							</div>
							<div className="d-flex align-items-center m-auto" style={{ maxWidth: 230 }}>
								<h6 className="d-inline">3º Lugar:</h6>
								<h5 className="d-inline ms-2">Célia Correia</h5>
							</div>
						</>
					)}
					<Image src={RAFFLE_IMG} alt="prize draw raffle" className="w-100" />
					{date.getTime() > new Date().getTime() ? (
						<h6 className="mt-2">
							{`Sorteio dia: ${date.toLocaleDateString('default', {
								day: '2-digit',
								month: 'long',
								year: 'numeric',
							})}`}
						</h6>
					) : (
						<h6 className="mt-2">
							{`Sorteio realizado no dia: ${date.toLocaleDateString('default', {
								day: '2-digit',
								month: 'long',
								year: 'numeric',
							})}`}
						</h6>
					)}
					{date.getTime() > new Date().getTime() && (
						<Container fluid className="mt-4">
							Liga para:{' '}
							<a className="link-secondary text-decoration-none" href={`tel:+351${contact}`}>
								+351 {contact}
							</a>
						</Container>
					)}
				</div>
			</div>
		</Container>
	);
};

export default RaffleSection;
