import { FC } from 'react';
import classNames from 'classnames';
import { Colors, IconFontSizes } from '../utils';

type IconProps = {
	name?: string;
	size?: IconFontSizes;
	color?: Colors;
	className?: string;
	style?: object;
};

const Icon: FC<IconProps> = ({ name = 'flag', size = '', color = '', className = '', style = {} }) => {
	return (
		<i
			className={classNames(`bi bi-${name}`, className)}
			style={{
				fontSize: size ? `${size}rem` : '',
				color: color ? `${color}` : '',
				...style,
			}}
		></i>
	);
};

export default Icon;
