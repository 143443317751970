import { ReactNode, FC } from 'react';
import classNames from 'classnames';

type Props = {
	header?: ReactNode;
	contentAfterHeader?: ReactNode;
	breadcrumbs?: ReactNode;
	children?: ReactNode;
	contentBeforeFooter?: ReactNode;
	footer?: ReactNode;
	maxWith?: boolean;
	hasAnimatedHeader?: boolean;
};

const LayoutSideMenu: FC<Props> = ({
	header = null,
	contentAfterHeader = null,
	breadcrumbs = null,
	children = null,
	contentBeforeFooter = null,
	footer = null,
	maxWith = false,
	hasAnimatedHeader = false,
}) => {
	return (
		<div className="layout-side-menu">
			{header}
			{contentAfterHeader}
			{breadcrumbs ? (
				<div className="breadcrumbs-wrapper">
					<div
						className={classNames('container mt-3 mt-lg-4 px-3 px-lg-4', {
							'max-width': maxWith,
						})}
					>
						{breadcrumbs}
					</div>
				</div>
			) : null}
			<div className="content">
				<div
					className={classNames({
						'max-width m-auto': maxWith,
						'p-4 px-xl-5 py-xl-4': !hasAnimatedHeader,
					})}
				>
					{children}
				</div>
			</div>
			{contentBeforeFooter}
			<div className="footer">{footer}</div>
		</div>
	);
};

export default LayoutSideMenu;
